import { useState, useEffect } from "react"

export default function Create_Link(props) {
    // State to manage the dropdown selection and text input
    const [selectedDomain, setSelectedDomain] = useState("clips.my.id")
    const [urlInput, setUrlInput] = useState("")
    const [shortenedUrl, setShortenedUrl] = useState("") // State for storing shortened URL
    const [isLinkCopied, setIsLinkCopied] = useState(false) // State to handle copy button status
    const [isVisible, setIsVisible] = useState(false) // State for animation effect
    const [isModalOpen, setIsModalOpen] = useState(false) // State for modal visibility
    const [isLoading, setIsLoading] = useState(false) // State for loading status
    const [isInputValid, setIsInputValid] = useState(true) // New state to track input validation
    const [showSuccessNote, setshowSuccessNote] = useState(false) // State to display login note
    const [errorMessage, setErrorMessage] = useState("")

    // URL validation function
    const isValidUrl = (url) => {
        const urlPattern = new RegExp(
            "^(https?:\\/\\/)" + // Protocol (http or https)
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // Domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IPv4 address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // Port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // Query string
                "(\\#[-a-z\\d_]*)?$", // Fragment locator
            "i"
        )

        // Validate the URL pattern first
        if (!urlPattern.test(url)) {
            return false
        }

        // Additional check for the domain part
        try {
            const { hostname } = new URL(url)
            const domainPattern = /^[a-zA-Z0-9.-]{2,}\.[a-zA-Z]{2,}$/
            return domainPattern.test(hostname)
        } catch {
            return false // If URL constructor fails, it's an invalid URL
        }
    }

    // Handle input change and validate
    const handleInputChange = (e) => {
        const inputValue = e.target.value
        setUrlInput(inputValue)

        if (inputValue === "") {
            setIsInputValid(false)
            setErrorMessage("URL cannot be empty.")
        } else if (!isValidUrl(inputValue)) {
            setIsInputValid(false)
            setErrorMessage("Invalid URL format.")
        } else {
            setIsInputValid(true)
            setErrorMessage("")
        }
    }

    // Handle form submission
    const handleSubmit = async () => {
        if (!isInputValid || urlInput.trim() === "") {
            // Prevent form submission if the input is invalid or empty
            return
        }

        setIsLoading(true) // Set loading to true when the request starts
        try {
            const response = await fetch("https://clips.id/api/free", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    original_url: urlInput,
                }),
            })
            const result = await response.json()
            setShortenedUrl(`https://${result.data.domain}/${result.data.slug}`)
            setIsLinkCopied(false)
            setshowSuccessNote(true)
        } catch (error) {
            console.error("Error shortening the link:", error)
        } finally {
            setIsLoading(false) // Set loading to false after the request completes
        }
    }

    // Handle copy to clipboard
    const handleCopy = () => {
        navigator.clipboard.writeText(shortenedUrl)
        setIsLinkCopied(true)
    }

    // Handle domain change
    const handleDomainChange = (e) => {
        const newDomain = e.target.value
        setSelectedDomain(newDomain)
        if (newDomain === "clips.id") {
            setIsModalOpen(true) // Open modal if "clips.id" is selected
            document.body.style.overflow = "hidden"
        }
    }

    // Handle modal close
    const closeModal = () => {
        setIsModalOpen(false)
        setSelectedDomain("clips.my.id") // Reset to default domain
        document.body.style.overflow = "auto"
    }

    useEffect(() => {
        setIsVisible(true) // Trigger animation on mount
    }, [])

    return (
        <div className={isVisible ? "appearing" : ""}>
            <div style={!showSuccessNote ? containerStyle : {}}>
                <style>
                    {`
                    @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

                    .custom-input-link::placeholder {
                        color: #b8bcd7;
                    }

                    .custom-input-link:focus, .custom-select-domain:focus {
                        outline: none;
                        border-color: #563aff !important;
                    }

                    @keyframes appearEffect {
                        0% {
                            opacity: 0;
                        }
                        100% {
                            opacity: 1;
                        }
                    }

                    .appearing {
                        opacity: 0;
                        animation: appearEffect 0.8s ease-out forwards;
                        animation-delay: 0.3s;
                    }

                    .modal-overlay {
                        position: fixed;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: rgba(0, 0, 0, 0.5);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 1000;
                    }

                    .modal-content {
                        background-color: #fff;
                        padding: 20px;
                        border-radius: 16px;
                        text-align: center;
                        max-width: 400px;
                        width: 100%;
                        margin: 20px;
                    }

                    .modal-content p {
                        font-family: 'Manrope', sans-serif;
                        font-weight: 500;
                        font-size: 1rem;
                    }

                    .modal-buttons {
                        display: flex;
                        justify-content: center;
                        gap: 8px;
                        margin-top: 20px;
                    }

                    .modal-button {
                        width: 100%;
                        max-width: 160px;
                        padding: 10px 20px;
                        font-size: 16px;
                        border: none;
                        border-radius: 5px;
                        cursor: pointer;
                    }

                    .modal-button-close {
                        width: 100%;
                        max-width: 160px;
                        background-color: #f4f7fe;
                        border-radius: 8px;
                        font-size: 1rem;
                    }

                    .modal-button-login {
                        background-color: rgb(86, 58, 255);
                        color: #fff;
                        border-radius: 8px;
                        font-size: 1rem;
                    }

                    .error-container {
                        width: 100%;
                        margin-top: 8px;
                        padding: 6px 20px;
                        background-color: #fff;
                        border-radius: 10px;
                    }

                    .error-message {
                        color: red;
                        font-family: 'Manrope', sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                    }
                `}
                </style>

                {shortenedUrl ? (
                    <div style={containerStyle}>
                        <input
                            className="custom-input-link"
                            style={inputStyle}
                            type="text"
                            value={shortenedUrl}
                            readOnly
                        />
                        <button style={buttonStyle} onClick={handleCopy}>
                            {isLinkCopied ? "Copied" : "Copy"}
                        </button>
                    </div>
                ) : (
                    <>
                        <select
                            className="custom-select-domain"
                            style={selectStyle}
                            value={selectedDomain}
                            onChange={handleDomainChange}
                        >
                            <option value="clips.my.id">clips.my.id</option>
                            <option value="clips.id">clips.id</option>
                        </select>

                        <input
                            className="custom-input-link"
                            style={{
                                ...inputStyle,
                                borderColor: isInputValid ? "#dbe3eb" : "red", // Change border color based on validity
                            }}
                            type="text"
                            placeholder="https://your-link.com/abc"
                            value={urlInput}
                            onChange={handleInputChange}
                        />

                        <button
                            style={buttonStyle}
                            onClick={handleSubmit}
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                >
                                    <g>
                                        <circle
                                            cx="3"
                                            cy="12"
                                            r="2"
                                            fill="white"
                                        />
                                        <circle
                                            cx="21"
                                            cy="12"
                                            r="2"
                                            fill="white"
                                        />
                                        <circle
                                            cx="12"
                                            cy="21"
                                            r="2"
                                            fill="white"
                                        />
                                        <circle
                                            cx="12"
                                            cy="3"
                                            r="2"
                                            fill="white"
                                        />
                                        <circle
                                            cx="5.64"
                                            cy="5.64"
                                            r="2"
                                            fill="white"
                                        />
                                        <circle
                                            cx="18.36"
                                            cy="18.36"
                                            r="2"
                                            fill="white"
                                        />
                                        <circle
                                            cx="5.64"
                                            cy="18.36"
                                            r="2"
                                            fill="white"
                                        />
                                        <circle
                                            cx="18.36"
                                            cy="5.64"
                                            r="2"
                                            fill="white"
                                        />
                                        <animateTransform
                                            attributeName="transform"
                                            dur="1.5s"
                                            repeatCount="indefinite"
                                            type="rotate"
                                            values="0 12 12;360 12 12"
                                        />
                                    </g>
                                </svg>
                            ) : (
                                "Shorten"
                            )}
                        </button>
                    </>
                )}

                {/* Modal */}
                {isModalOpen && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <p>Please login to shorten with this domain.</p>
                            <div className="modal-buttons">
                                <button
                                    className="modal-button modal-button-close"
                                    onClick={closeModal}
                                >
                                    Close
                                </button>
                                <button
                                    className="modal-button modal-button-login"
                                    onClick={() => {
                                        window.location.href =
                                            "https://app.clips.id/"
                                    }}
                                >
                                    Login
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* Error text */}
            {!isInputValid && (
                <div className="error-container">
                    {errorMessage && (
                        <p className="error-message">{errorMessage}</p>
                    )}
                </div>
            )}

            {/* Note text */}
            {showSuccessNote && (
                <div style={noteStyle}>
                    To unlock full access to edit and customize your link,
                    please{" "}
                    <a href="https://app.clips.id/" style={linkStyle}>
                        login
                    </a>
                    .
                </div>
            )}
        </div>
    )
}

// Styles
const containerStyle = {
    backgroundColor: "#fff",
    height: "100%",
    width: "100%", // Set container width to 100%
    borderRadius: "16px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    padding: "8px",
    boxSizing: "border-box",
}

const selectStyle = {
    backgroundColor: "#fff",
    padding: "10px",
    fontSize: "16px",
    width: "70%",
    borderRadius: "10px",
    border: "1px solid #dbe3eb",
    outline: "none",
    color: "#1d054f",
}

const inputStyle = {
    padding: "10px",
    fontSize: "16px",
    width: "100%", // Make the input fill the container
    borderRadius: "10px",
    border: "1px solid #dbe3eb",
    color: "#1d054f",
}

const buttonStyle = {
    height: "40px",
    backgroundColor: "rgb(86, 58, 255)",
    borderRadius: "10px",
    color: "#fff",
    padding: "10px 14px",
    fontSize: "16px",
    border: "none",
    cursor: "pointer",
}

const noteStyle = {
    width: "100%",
    marginTop: "20px",
    textAlign: "center",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#fff",
}

const linkStyle = {
    color: "#563aff",
    textDecoration: "underline",
    padding: "2px 4px",
    borderRadius: "4px",
    backgroundColor: "#fff",
}
